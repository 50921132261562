import ReactDom from "react-dom";

import Snowflakes from "../Snowflakes/Snowflakes";

const Modal = ({ handleOpen, onClose, children, modalBackground }) => {
  if (!handleOpen) return null;
  return ReactDom.createPortal(
    <div className="modal-container">
      <Snowflakes />
      <div className="overlay-style" onClick={onClose} />
      <div
        className="modal-style"
        style={{
          backgroundImage: `url(${
            require("../../assets/images/CalendarImages" + modalBackground.modalBackground).default
          })`,
          backgroundSize: "cover"
        }}
      >
        <div className="close-button" onClick={onClose}>
          ✕
        </div>
        {children}
      </div>
    </div>,
    document.getElementById("modal_popUp")
  );
};

export default Modal;
