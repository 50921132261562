const Logos = {
  copyright_myfeldt_logo: require("../assets/images/homePage/My_Feldt_Copyright.svg").default,
  snallkalendern_logo: require("../assets/images/homePage/snall-logo.svg").default,
  snallkalendern_logo_green: require("../assets/images/homePage/snällkalendern_logo_green.svg").default,
  friends_logo: require("../assets/images/homePage/friends_logo.svg").default,
  tietoevry_logo: require("../assets/images/homePage/tietoevry_logo.svg").default,
  my_feldt_logo: require("../assets/images/homePage/Signature_My_feldt.svg").default,
};

export default Logos;
